import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InventoryFinder from "../../apis/InventoryFinder";
import { useNavigate } from "react-router-dom";
import PopupWeb from "../Popup/PopupNew";
import { BsArrowLeftRight } from "react-icons/bs";
import { MdChangeCircle } from "react-icons/md";
import { FcInspection } from "react-icons/fc";

import { ItemPageContext } from "../../context/ItemPageContext";

import { BiEdit } from "react-icons/bi";

import ItemPageHistory from "./ItemPageHistory";

import { useAuth } from "../../context/AuthContext";

import ClipLoader from "react-spinners/ClipLoader";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ItemPageInspectionTask from "./ItemPageInspectionTaskNew";

import { useMainItemPageData } from "./fetchItemPageData";
import Select from "react-select";
import { MdOutlineQrCode } from "react-icons/md";
import { usePageTitle } from "../Navbar/PageTitleContext";

const ItemPageHeader = () => {
  const { id } = useParams();
  //// console.log(id);
  const appURL = process.env.REACT_APP_Site_URL;
  const { setPageTitle } = usePageTitle();

  const [loading, setLoading] = useState(false);
  //
  //
  const {
    currentUserId,
    currentUserSiteId,
    currentFirstName,
    currentLastName,
  } = useAuth();

  const { setItemHistory } = useContext(ItemPageContext);

  const { setItemScheduledEvent } = useContext(ItemPageContext);
  const {
    setEventList,
    setItemUsage,
    itemInfo,
    setItemInfo,
    // itemInspection,
    setItemInspection,
    setItemId,
    setItemInspectionAlerts,
    inspectionOpen,
    setInspectionOpen,
  } = useContext(ItemPageContext);
  const { apparatusList, setApparatusList } = useContext(ItemPageContext);

  const [bags, setBags] = useState([]);

  const { data, refetch } = useMainItemPageData(currentUserSiteId, id);
  ///

  //
  //

  const [itemArea, setItemArea] = useState("");
  const [itemPersonal, setItemPersonal] = useState("");

  //reload page
  const history = useNavigate();

  const [selectedAmount, setSelectedAmount] = useState("");
  const [setSelectedIdentificationName] =
    useState("");

  const [newSelectedIdentificationName, setNewSelectedIdentificationName] =
    useState("");
  const [itemName, setItemName] = useState("");

  const [selectedSerialNumber, setSelectedSerialNumber] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedExpirationDate, setSelectedExpirationDate] = useState();
  const [selectedManufactureDate, setSelectedManufactureDate] = useState();
  const [selectedHydrostaticDate, setSelectedHydrostaticDate] = useState();

  const [oldItemAmount, setOldItemAmount] = useState();

  //Need to fix to not use state
  //const data2 = history.location.state;
  const [memberList, setMemberList] = useState([]);

  const [itemTags, setItemTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [addItemTags, setAddItemTags] = useState([]);
  const [allFrequency, setAllFrequency] = useState([]);

  useEffect(() => {
    if (data) {
      setItemInfo(data?.data.data.itemInfo);
      setInvid(data?.data.data.itemInfo[0].invid);
      setSelectedAmount(data?.data.data.itemInfo[0].amount);
      setOldItemAmount(data?.data.data.itemInfo[0].amount);
      setSelectedIdentificationName(
        data?.data.data.itemInfo[0].identificationname
      );
      setNewSelectedIdentificationName(
        data?.data.data.itemInfo[0].identificationname
      );
      // setSelectedSerialNumber(response.data.data.itemInfo[0].serialnumber);
      setItemInspectionAlerts(data?.data.data.alertdetailsandcomment);

      setSelectedSerialNumber(
        data?.data.data.itemInfo[0].serialnumber == null
          ? ""
          : data?.data.data.itemInfo[0].serialnumber
      );

      setSelectedStatus(data?.data.data.itemInfo[0].status);

      setItemName(data?.data.data.itemInfo[0].itemname);
      setEventList(data?.data.data.itemEventList);
      setItemHistory(data?.data.data.itemHistory);
      setItemArea(data?.data.data.itemArea);
      setItemPersonal(data?.data.data.itemPersonal);

      setItemScheduledEvent(data?.data.data.itemScheduledEvent);
      setItemInspection(data?.data.data.itemInspection);
    console.log(data?.data.data);
      setSelectedManufactureDate(
        data?.data.data.itemInfo[0].manufacturedate === null
          ? ""
          : new Date(data?.data.data.itemInfo[0].manufacturedate)
              .toISOString()
              .substring(0, 10)
      );
      setSelectedExpirationDate(
        data?.data.data.itemInfo[0].expirationdate === null
          ? ""
          : new Date(data?.data.data.itemInfo[0].expirationdate)
              .toISOString()
              .substring(0, 10)
      );
      setSelectedHydrostaticDate(
        data?.data.data.itemInfo[0].mandatoryinspectiondate === null
          ? ""
          : new Date(data?.data.data.itemInfo[0].mandatoryinspectiondate)
              .toISOString()
              .substring(0, 10)
      );

      setAddItemTags(data?.data.data.itemTags);
      setApparatusList(data?.data.data.apparatusListNew);
      setItemUsage(data?.data.data.itemUsage);
      setItemHistory(data?.data.data.itemHistory);

      setItemId(id);

      setMemberList(data?.data.data.memberList);
      setBags(data?.data.data.bagsListNew);
      setItemTags(data?.data.data.itemTags);
      setAllTags(data?.data.data.allTags);
      setAllFrequency(data?.data.data.allFrequency);

      setPageTitle( data?.data.data.itemInfo[0].identificationname ? data?.data.data.itemInfo[0].identificationname : data?.data.data.itemInfo[0].itemname ); // Set a dynamic page title

      
    } // eslint-disable-next-line
  }, [data]);

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    const newErrors = {};
    if (!addMember && !selectedCompartmentId && !selectedBagId) {
      newErrors.general = "Please select at least one option";
    } else if (
      (addMember && selectedCompartmentId) ||
      (addMember && selectedBagId) ||
      (selectedCompartmentId && selectedBagId)
    ) {
      newErrors.general = "Please select only one option";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  
  const clearForm = () => {
    setErrors({});
  };


  const handleAddMemberChange = (e) => {
    setAddMember(e ? e.value : null);
    setSelectedCompartmentId(null);
    setSelectedBagId(null);
    setErrors({});
  };

  const handleCompartmentChange = (e) => {
    setAddMember(null);
    setSelectedCompartmentId(e ? e.value : null);
    setSelectedBagId(null);
    setErrors({});
  };

  const handleBagChange = (e) => {
    setAddMember(null);
    setSelectedCompartmentId(null);
    setSelectedBagId(e ? e.value : null);
    setErrors({});
  };


  //update inventory
  const handleUpdate = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);

      // console.log("dmdkmfds");
      const response = await InventoryFinder.post(`/editItem`, {
        itemid: id,
        selectedAmount,
        oldItemAmount,
        invid,
        selectedIdentificationName: newSelectedIdentificationName,
        selectedSerialNumber:
          selectedSerialNumber === "" ? null : selectedSerialNumber,
        selectedManufactureDate:
          selectedManufactureDate === "" ? null : selectedManufactureDate,
        selectedExpirationDate:
          selectedExpirationDate === "" ? null : selectedExpirationDate,
        selectedHydrostaticDate:
          selectedHydrostaticDate === "" ? null : selectedHydrostaticDate,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
        addItemTags,
      });
      // console.log("rerwr");

      setLoading(false);
      // console.log("dmdkmfds");
      //(response.data.data.alert);
      if (response.data.data.alert) {
        alert(response.data.data.alert);
        return;
      }

      refetch();

      editItemPopupClose();
    } catch (err) {
      setLoading(false);
    }
  };

  //Item move submit
  const handleSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {

      if (!validateForm()) {
        return;
      }

      setLoading(true);

      // eslint-disable-next-line
      const response = await InventoryFinder.post(`/moveItem`, {
        selectedCompartmentId,
        selectedBagId,
        invid,
        itemid: id,
        currentUserId,
        selectedApparatusId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
        addMember,
      });
      setLoading(false);
      refetch();
      toggleItemMove();
      clearForm();
    } catch (err) {
      setLoading(false);
    }
  };

  //Popup
  const [isOpen1, setIsOpen] = useState(false);

  //Popup QR Code
  const [isOpenQR, setIsOpenQR] = useState(false);
  const addPopupQR = () => {
    setIsOpenQR(!isOpenQR);
  };

  //Popup Edit item details
  const [isOpen3, setIsOpen3] = useState(false);
  const editItemPopupClose = () => {
    setIsOpen3(!isOpen3);
  };

  
  const [isInspectionFreqOpen, setIsInspectionFreqOpen] = useState(false);
  const mainInspectionFreqPopup = () => {
    setIsInspectionFreqOpen(!isInspectionFreqOpen);
  };

  const editItemPopup = () => {
    setIsOpen3(!isOpen3);
  };

  //Input useState
  const [invid, setInvid] = useState("");
  const [selectedCompartmentId, setSelectedCompartmentId] = useState("");
  const [selectedBagId, setSelectedBagId] = useState("");
  const [selectedApparatusId, setSelectedApparatusId] = useState("");

  //select bag Popup
  const [PopupDelete, setPopupDelete] = useState(false);

  const toggleDelete = () => {
    setPopupDelete(!PopupDelete);
    setIsOpen3(!isOpen3);
  };

  const toggleDelete2 = () => {
    setPopupDelete(!PopupDelete);
  };

  //Delete apparatus

  const handleDelete = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      // eslint-disable-next-line
      const response = await InventoryFinder.post(`/deleteItem`, {
        itemid: id,
        oldItemAmount,
        invid,
        currentUserId,
        currentUserSiteId,
      });

      handleItem(invid);
    } catch (err) {}
  };

  const handleItem = (id) => {
    history(`/inventory/${id}/item`);
  };

  const handleCompartment = (apparatusId, compartmentId) => {
    history({
      pathname: `/apparatus/${apparatusId}`,
      state: { compartmentId: compartmentId },
    });
  };

  const handleBag = (apparatusId, bagId) => {
    history({
      pathname: `/apparatus/${apparatusId}`,
      state: { bagId: bagId },
    });
  };

  const sideBar = document.getElementById("sidebar");

  const [itemStatusPopup, setItemStatusPopup] = useState(false);

  const toggleItemStatus = () => {
    setItemStatusPopup(!itemStatusPopup);
  };

  const toggleItemStatus2 = () => {
    setItemStatusPopup(!itemStatusPopup);
  };

  const toggleItemStatus3 = () => {
    //setItemInfoOpen(!itemInfoOpen);

    setInspectionOpen(!inspectionOpen);
  };

  const handleOptionChange = (value) => {
    console.log(value);
    setSelectedStatus(value.target.value);
  };

  const handleStatusSubmit = async (e) => {
    try {
      e.preventDefault();
      // eslint-disable-next-line
      const response = await InventoryFinder.post(`/changeItemStatus`, {
        itemid: id,
        selectedStatus,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
      });
      refetch();

      toggleItemStatus();
    } catch (err) {
      // console.log(err);
    }
  };

  const [isItemMoveOpen, setIsItemMoveOpen] = useState(false);

  const toggleItemMove = () => {
    setIsItemMoveOpen(!isItemMoveOpen);
    setAddMember("");
    setSelectedBagId("");
    setSelectedApparatusId("");
  };

  const toggleItemMove2 = () => {
    setIsItemMoveOpen(!isItemMoveOpen);
  };

  const [addMember, setAddMember] = useState("");

  const [addFreq, setAddFreq] = useState("");

  let options = memberList.map(function (data) {
    return {
      value: data.user_id,
      label: data.firstname + " " + data.lastname,
      id: data.user_id,
    };
  });

  const optionsApparatus = apparatusList.map((data) => ({
    label: data.name,
    options: data.compartments.map((data2) => ({
      value: data2.cid,
      label: data2.compartmentname,
    })),
  }));

  const flatOptionsApparatus = optionsApparatus.flatMap(group => group.options);


  let optionsBags = bags.map(function (data) {
    return {
      label: data.name,
      options: data.bags.map((data2, i) => ({
        value: data2.bagid,
        label: data2.bagname,
      })),
    };
  });

  const flatOptionsBags = optionsBags.flatMap(group => group.options);


  let optionsAllTags = allTags.map(function (data) {
    return {
      value: data.categoryid,
      label: data.name,
    };
  });

  let optionsTags = itemTags.map(function (data) {
    return {
      value: data.value,
      label: data.label,
    };
  });

  let optionsFreq = allFrequency.map(function (data) {
    return {
      value: data.id,
      label: data.name,
    };
  });

  //Item move submit
  const handleFreqSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);

      // eslint-disable-next-line
      const response = await InventoryFinder.post(`/updateItemFreq`, {
        isCheck: [id],
        addFreq,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
      });
      setLoading(false);
      refetch();
      mainInspectionFreqPopup();
    } catch (err) {
      setLoading(false);
    }
  };

  const statusOptions = [
    { value: 'Good', label: 'Good' },
    { value: 'Worn', label: 'Worn' },
    { value: 'Bad', label: 'Bad' },
    { value: 'Monitor', label: 'Monitor' },
    { value: 'Out For Repair', label: 'Out For Repair' },
    { value: 'Missing', label: 'Missing' },
    { value: 'Retired', label: 'Retired' },
  ];


  const handleUnassign = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {

  

      setLoading(true);

      // eslint-disable-next-line
      const response = await InventoryFinder.post(`/unassignItem`, {
        invid,
        itemid: id,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
        addMember,
      });
      setLoading(false);
      refetch();
      toggleItemMove();
      clearForm();
    } catch (err) {
      setLoading(false);
    }
  };


  return (
    <>
     


      <div className="silderHolderItemPage">
        <Tabs>
          <TabList>
            <Tab>
              <b>About</b>
            </Tab>
        {/*}   <Tab>
              <b>Usage</b>
            </Tab>*/} 
            <Tab>
              <b>History</b>
            </Tab>
          </TabList>

          <TabPanel>
            {itemInfo &&
              itemInfo.map((data, i) => {
                console.log(itemInfo);
                return (
                  <div key={i}>
                    <div className="itemPageSideRightContainer">
                      <div className="itemPageAmountContainer">
                        <div className="itemPageAmountName">
                          <b>Amount</b>
                        </div>
                        <div className="itemPageAmountNumber">
                          <b>{data.amount}</b>
                        </div>
                      </div>
                      <div className="itemPageAmountContainer">
                        <div className="itemPageAmountName">
                          <b>Item</b>
                        </div>
                        <div className="itemPageAmountNumber">
                          <b>{data.itemname}</b>
                        </div>
                      </div>

                      <div className="itemPageAmountContainer">
                        <div className="itemPageAmountName">
                          <b>Identification Name</b>
                        </div>
                        <div className="itemPageAmountNumber">
                          <b>{data.identificationname}</b>
                        </div>
                      </div>

                      <div className="itemPageAmountContainer">
                        <div className="itemPageAmountName">
                          <b>Serial Number</b>
                        </div>
                        <div className="itemPageAmountNumber">
                          <b>{data.serialnumber}</b>
                        </div>
                      </div>

                      <div className="itemPageAmountContainer">
                        <div className="itemPageAmountName">
                          <b>Status</b>
                        </div>
                        <div className="itemPageAmountNumber">
                          <b>{data.status}</b>
                        </div>
                      </div>

           

                      <div className="itemPageAmountContainer">
                        <div className="itemPageAmountName">
                          <b>Inspection date</b>
                        </div>
                        <div className="itemPageAmountNumber">
                          <b>{data.inspectiondate}</b>
                        </div>
                      </div>

                      <div className="itemPageAmountContainer">
                        <div className="itemPageAmountName">
                          <b>Next Inspection date</b>
                        </div>
                        <div className="itemPageAmountNumber">
                          <b>{data.nextinspectiondate}</b>
                        </div>
                      </div>
                      
                      <div className="itemPageAmountContainer">
                        <div className="itemPageAmountName">
                          <b>Inspection Frequency</b>
                        </div>
                        <div className="itemPageAmountNumber">
                          <b>{data.inspectionfreq}</b>
                        </div>
                      </div>

                      <div className="itemPageAmountContainer">
                        <div className="itemPageAmountName">
                          <b>Manufacture date</b>
                        </div>
                        <div className="itemPageAmountNumber">
                          <b>{data.manufacturedate}</b>
                        </div>
                      </div>

                      <div className="itemPageAmountContainer">
                        <div className="itemPageAmountName">
                          <b>Mandatory Service Date</b>
                        </div>
                        <div className="itemPageAmountNumber">
                          <b>{data.mandatoryinspectiondate}</b>
                        </div>
                      </div>

                      <div className="itemPageAmountContainer">
                        <div className="itemPageAmountName">
                          <b>Expiration date</b>
                        </div>
                        <div className="itemPageAmountNumber">
                          <b>{data.expirationdate}</b>
                        </div>
                      </div>

                      <div className="itemPageAmountContainer">
                        <div className="itemPageAmountName">
                          <b>Assigned To</b>
                        </div>
                        <div className="itemPageAmountNumber2">
                          <b>
                            {itemArea.length > 0 ? (
                              itemArea.map((data2, i) => {
                                return (
                                  <div
                                    key={i}
                                    onClick={() =>
                                      data2.bagname
                                        ? handleBag(
                                            data2.apparatusid,
                                            data2.bagid
                                          )
                                        : handleCompartment(
                                            data2.apparatusid,
                                            data2.cid
                                          )
                                    }
                                  >
                                    {data2.apparatusname
                                      ? `${data2.apparatusname}`
                                      : ""}

                                    {data2.name ? ` - ${data2.name}` : ""}
                                    {data2.bagname ? ` - ${data2.bagname}` : ""}
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}

                            {itemPersonal.length > 0 ? (
                              itemPersonal.map((data2, i) => {
                                return (
                                  <div key={i}>
                                    {data2.firstname} {data2.lastname}
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </b>
                        </div>
                      </div>

                      <div className="itemPageAmountContainer2">
                        <div className="itemPageAmountName">
                          <b>Tags</b>
                        </div>
                        <div className="itemPageAmountNumber tagsContainer">
                          {itemTags.length > 0 ? (
                            itemTags.map((tag, i) => (
                              <div key={i} className="itemTag">
                                {typeof tag === "string" ? (
                                  tag
                                ) : (
                                  <b>{tag.label}</b>
                                )}
                                {/*<button className="removeTagButton" onClick={() => handleRemoveTag(tag)}>×</button>*/}
                              </div>
                            ))
                          ) : (
                            <div>No tags</div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="quickButtonContainer">
                      <div
                        className="quickButtonHolder"
                        onClick={toggleItemMove2}
                      >
                        <div className="quickButtonIcon">
                          <b>
                            <BsArrowLeftRight />
                          </b>
                        </div>
                        <div className="quickButtonName">
                          <b>Move</b>
                        </div>
                      </div>

                      <div
                        className="quickButtonHolder"
                        onClick={toggleItemStatus2}
                      >
                        <div className="quickButtonIcon">
                          <b>
                            <MdChangeCircle />
                          </b>
                        </div>
                        <div className="quickButtonName">
                          <b>Status</b>
                        </div>
                      </div>

                      <div
                        className="quickButtonHolder"
                        onClick={toggleItemStatus3}
                      >
                        <div className="quickButtonIcon">
                          <b>
                            <FcInspection />
                          </b>
                        </div>
                        <div className="quickButtonName">
                          <b>Inspect</b>
                        </div>
                      </div>

                      <div
                        className="quickButtonHolder"
                        onClick={editItemPopup}
                      >
                        <div className="quickButtonIcon">
                          <b>
                            <BiEdit />
                          </b>
                        </div>
                        <div className="quickButtonName">
                          <b>Update </b>
                        </div>
                      </div>

                      <div className="quickButtonHolder" onClick={addPopupQR}>
                        <div className="quickButtonIcon">
                          <b>
                            <MdOutlineQrCode />
                          </b>
                        </div>
                        <div className="quickButtonName">
                          <b>QR</b>
                        </div>
                      </div>
                      <div
                        className="quickButtonHolder"
                        onClick={mainInspectionFreqPopup}
                      >
                        <div className="quickButtonIcon">
                          <b>
                            <BiEdit />
                          </b>
                        </div>
                        <div className="quickButtonName">
                          <b>Inspect Freq</b>
                        </div>
                      </div>

                      {/* <div className="quickButtonHolder">
                  <div className="quickButtonIcon">
                    <b>
                      <MdChangeCircle />
                    </b>
                  </div>
                  <div className="quickButtonName">
                    <b>Inspections</b>
                  </div>
              </div>*/}
                    </div>
                  </div>
                );
              })}
          </TabPanel>

      {/*    <TabPanel>
            <ItemPageUsage />
          </TabPanel>*/} 
          <TabPanel>
            <ItemPageHistory />
          </TabPanel>
        </Tabs>
      </div>

      <div className="itemPageBottom"></div>

      {isOpen3 && (
      <PopupWeb
        content={
          <>
            <form onSubmit={handleUpdate}>
              <div className="emptySpace"></div>
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>
              <div className="popupContainer">
                {itemInfo &&
                  itemInfo.map((data, i) => {
                    return (
                      <div key={i}>
                        <label
                          className="NewInputLabel"
                          data-domain="Identification Name"
                        >
                          <input
                            className="NewInput"
                            type="text"
                            placeholder=""
                            value={newSelectedIdentificationName}
                            onChange={(e) =>
                              setNewSelectedIdentificationName(e.target.value)
                            }
                          />
                        </label>

     
                        <label className="NewInputLabel" data-domain="Amount">
                          <input
                            className="NewInput"
                            type="number"
                            placeholder=""
                            value={selectedAmount}
                            onChange={(e) =>
                              setSelectedAmount(e.target.value)
                            }
                            required
                          />
                        </label>
                        <label
                          className="NewInputLabel"
                          data-domain="Serial Number"
                        >
                          <input
                            className="NewInput"
                            type="text"
                            placeholder=""
                            value={selectedSerialNumber}
                            onChange={(e) =>
                              setSelectedSerialNumber(e.target.value)
                            }
                          />
                        </label>

                        <label className="NewInputLabel" data-domain="Tags">
                          <Select
                            isMulti
                            options={optionsAllTags}
                            onChange={setAddItemTags}
                            name="addMembers"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            defaultValue={optionsTags}
                            maxMenuHeight={150}
                          />
                        </label>

                        <label
                          className="NewInputLabel"
                          data-domain="Manufacture Date"
                        >
                          <input
                            className="NewInput"
                            type="date"
                            value={selectedManufactureDate}
                            onChange={(e) =>
                              setSelectedManufactureDate(e.target.value)
                            }
                            onFocus={(event) =>
                              (event.nativeEvent.target.defaultValue = "")
                            }
                          />
                        </label>
                        <label
                          className="NewInputLabel"
                          data-domain="Retirement Date"
                        >
                          <input
                            className="NewInput"
                            type="date"
                            value={selectedExpirationDate}
                            onChange={(e) =>
                              setSelectedExpirationDate(e.target.value)
                            }
                            onFocus={(event) =>
                              (event.nativeEvent.target.defaultValue = "")
                            }
                          />
                        </label>
                        <label
                          className="NewInputLabel"
                          data-domain="Mandatory Service Date"
                        >
                          <input
                            className="NewInput"
                            type="date"
                            value={selectedHydrostaticDate}
                            onChange={(e) =>
                              setSelectedHydrostaticDate(e.target.value)
                            }
                            onFocus={(event) =>
                              (event.nativeEvent.target.defaultValue = "")
                            }
                          />
                        </label>
                      </div>
                    );
                  })}
              </div>
            </form>
          </>
        }
        handleClose={editItemPopupClose}
        isOpen={isOpen3}
        handleSubmit={handleUpdate}
        showSubmitButton={true}
        headerText={<b>Edit Item Details</b>}
        submitButtonText={loading ? "Loading..." : "Update"} // Optionally change the text to indicate loading
      />
    )}


      {isOpenQR && (
        <PopupWeb
          content={
            <>
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div className="addMemberBtn">
                    {appURL}/equipment?itemId:{id}
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={addPopupQR}
          isOpen={isOpenQR}
          showSubmitButton={false}
          headerText="QR Code Link"
        />
      )}

      {PopupDelete && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>Delete - {itemName} </b>
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>This item will no longer be available</b>
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>
                  THIS WILL PERMANENTLY DELETE THE ITEM AND WILL REMOVE ALL
                  HISTORY FROM THE ITEM
                </b>
              </div>

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={handleDelete}
                  //onClick={togglePopup2}
                >
                  <b>Delete</b>
                </div>
              </div>

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={toggleDelete}
                >
                  <b>Cancel</b>
                </div>
              </div>
            </>
          }
          handleClose={toggleDelete2}
          isOpen={PopupDelete}
          handleSubmit={handleDelete}
          showSubmitButton={true}
          headerText={<b>Delete - {itemName} </b>}
        />
      )}

{itemStatusPopup && (
  <PopupWeb
    content={
      <>
        <form onSubmit={handleStatusSubmit}>
          <div className="uniquePopupContainer">
            <div className="uniqueMiddleContainer">
              <div className="uniqueMiddle">
                <div className="uniqueItemStatusHeaderContainer">
                  <ul className="uniqueItemStatusList">
                    <li className="uniqueItemStatusListItem">
                      <div className="uniqueRadioLabelWrapper">
                        <label htmlFor="uniqueOptionGood">Good</label>
                        <input
                          type="radio"
                          id="uniqueOptionGood"
                          name="uniqueStatusSelector"
                          value={"Good"}
                          checked={selectedStatus === "Good"}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </li>
                    <li className="uniqueItemStatusListItem">
                      <div className="uniqueRadioLabelWrapper">
                        <label htmlFor="uniqueOptionWorn">Worn</label>
                        <input
                          type="radio"
                          id="uniqueOptionWorn"
                          name="uniqueStatusSelector"
                          value={"Worn"}
                          checked={selectedStatus === "Worn"}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </li>
                    <li className="uniqueItemStatusListItem">
                      <div className="uniqueRadioLabelWrapper">
                        <label htmlFor="uniqueOptionBad">Bad</label>
                        <input
                          type="radio"
                          id="uniqueOptionBad"
                          name="uniqueStatusSelector"
                          value={"Bad"}
                          checked={selectedStatus === "Bad"}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </li>
                    <li className="uniqueItemStatusListItem">
                      <div className="uniqueRadioLabelWrapper">
                        <label htmlFor="uniqueOptionMonitor">Monitor</label>
                        <input
                          type="radio"
                          id="uniqueOptionMonitor"
                          name="uniqueStatusSelector"
                          value={"Monitor"}
                          checked={selectedStatus === "Monitor"}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </li>
                    <li className="uniqueItemStatusListItem">
                      <div className="uniqueRadioLabelWrapper">
                        <label htmlFor="uniqueOptionOutOfService">Out Of Service</label>
                        <input
                          type="radio"
                          id="uniqueOptionOutOfService"
                          name="uniqueStatusSelector"
                          value={"Out Of Service"}
                          checked={selectedStatus === "Out Of Service"}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </li>
                    <li className="uniqueItemStatusListItem">
                      <div className="uniqueRadioLabelWrapper">
                        <label htmlFor="uniqueOptionMissing">Missing</label>
                        <input
                          type="radio"
                          id="uniqueOptionMissing"
                          name="uniqueStatusSelector"
                          value={"Missing"}
                          checked={selectedStatus === "Missing"}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </li>
                    <li className="uniqueItemStatusListItem">
                      <div className="uniqueRadioLabelWrapper">
                        <label htmlFor="uniqueOptionRetired">Retired</label>
                        <input
                          type="radio"
                          id="uniqueOptionRetired"
                          name="uniqueStatusSelector"
                          value={"Retired"}
                          checked={selectedStatus === "Retired"}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    }
    handleClose={toggleItemStatus}
    isOpen={itemStatusPopup}
    handleSubmit={handleStatusSubmit}
    showSubmitButton={true}
    headerText={<b>Set Status</b>}
    submitButtonText={loading ? "Loading..." : "Submit"} // Optionally change the text to indicate loading
  />
)}



      {isItemMoveOpen && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleSubmit}>


              <label className="NewInputLabel" data-domain="Members">
                  <Select
                    options={options}
                    onChange={handleAddMemberChange}
                    value={options.find(option => option.value === addMember) || null}
                    name="addMembers"
                    className="basic-select"
                    classNamePrefix="select"
                    maxMenuHeight={150}
                  />
                  {errors.addMember && (
                    <div className="errorInputLabel">{errors.addMember}</div>
                  )}
                </label>

                <label className="NewInputLabel" data-domain="Location">
                  <Select
                    options={optionsApparatus}
                    onChange={handleCompartmentChange}
                    value={flatOptionsApparatus.find(option => option.value === selectedCompartmentId) || null}
                    name="addMembers"
                    className="basic-select"
                    classNamePrefix="select"
                    maxMenuHeight={150}
                  />
                  {errors.selectedCompartmentId && (
                    <div className="errorInputLabel">{errors.selectedCompartmentId}</div>
                  )}
                </label>

                <label className="NewInputLabel" data-domain="Bags">
                  <Select
                    options={optionsBags}
                    onChange={handleBagChange}
                    value={flatOptionsBags.find(option => option.value === selectedBagId) || null}
                    name="addMembers"
                    className="basic-select"
                    classNamePrefix="select"
                    maxMenuHeight={150}
                  />
                  {errors.selectedBagId && (
                    <div className="errorInputLabel">{errors.selectedBagId}</div>
                  )}
                </label>

                {errors.general && (
                  <div className="errorInputLabel">{errors.general}</div>
                )}

              
<div className="popup-unassign-container">
            <button className="unassign-button" onClick={handleUnassign}>
              Unassign
            </button>
          </div>
              </form>
            </>
          }

          handleClose={toggleItemMove}
          isOpen={isItemMoveOpen}
          handleSubmit={handleSubmit}
          showSubmitButton={true}
          headerText={<b>Reassign Item</b>}
          submitButtonText={loading ? "Loading..." : "Submit"} // Optionally change the text to indicate loading
          loading={loading}


        />
        
      )}

      {isInspectionFreqOpen && (
        <PopupWeb
          content={
            <>
      
              <form onSubmit={handleFreqSubmit}>
  
                <div className="holderTestEmpty"></div>
            
                <label className="NewInputLabel" data-domain="Frequency">
                <Select
                    options={optionsFreq}
                    onChange={(e) => setAddFreq(e.value)}
                    name="addMembers"
                    className="basic-multi-select"
                    classNamePrefix="select"               
                    maxMenuHeight={150}
                  />
                </label>
              </form>
            </>
          }

          handleClose={mainInspectionFreqPopup}
          isOpen={isInspectionFreqOpen}
          handleSubmit={handleFreqSubmit}
          showSubmitButton={true}
          headerText={<b>Inspection Frequency</b>}
          submitButtonText={loading ? "Loading..." : "Submit"} // Optionally change the text to indicate loading


        />
      )}

      {inspectionOpen && <ItemPageInspectionTask />}
    </>
  );
};

export default ItemPageHeader;
