import React, { useState, useRef, useEffect } from "react";

import PopupWeb from "../Popup/PopupNew";

import TrainingFinder from "../../apis/TrainingFinder";

import { useNavigate } from "react-router-dom";
import PublicFolder from "../../apis/PublicFolder";

import Header from "../Header/Header";
import { useAuth } from "../../context/AuthContext";

import TextareaAutosize from "react-textarea-autosize";
import { AiOutlineMenu } from "react-icons/ai";

import { useTrainingData } from "./fetchTrainingData";

import Select from "react-select";
import { notify } from "../../ToastNotifier";
import {
  ChevronDownIcon,
  AdjustmentsHorizontalIcon,
} from "@heroicons/react/20/solid";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";

import PDFReportTemplate from "../Reports/PDFReportTemplate";

const Pagination = ({ table }) => {
  const { pageIndex, pageSize } = table.getState().pagination;

  // Store the current page index in local storage
  useEffect(() => {
    localStorage.setItem("EventPage", pageIndex);
  }, [pageIndex]);

  const totalRows = table.getPrePaginationRowModel().rows.length;
  const startRow = pageIndex * pageSize + 1;
  const endRow = Math.min(startRow + pageSize - 1, totalRows);

  return (
    <div className="pagination2">
      <div className="results-info">
        Showing {startRow} to {endRow} of {totalRows} results
      </div>
      <div className="pagination-controls">
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </button>
        <span>
          {/*  Page {pageIndex + 1} of {table.getPageCount()}*/}
          {pageIndex + 1}
        </span>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const EventHeader = () => {
  const { currentUserSiteId } = useAuth();
  const { data, refetch } = useTrainingData(currentUserSiteId);

  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    setEventList(data?.data.data.trainingList);
  }, [data]);

  //reload page
  const history = useNavigate();
  const [loading, setLoading] = useState(false);

  const [categoryFilter, setCategoryFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [memberFilter, setMemberFilter] = useState([]);
  const [fromDateFilter, setFromDateFilter] = useState("");
  const [thruDateFilter, setThruDateFilter] = useState("");

  const [selectedOptions, setSelectedOptions] = useState([]);

  //Popup create new events
  const [isOpen7, setIsOpen7] = useState(false);
  const newUpcomingPopupClose = () => {
    setIsOpen7(!isOpen7);
    clearForm();
  };

  const newUpcomingPopup = () => {
    setIsOpen7(!isOpen7);
    // setIsOpen8(!isOpen8);
  };

  //Popup create new events
  const [isOpen8, setIsOpen8] = useState(false);
  const newSelectPopup = () => {
    setIsOpen8(!isOpen8);
  };

  const options =
    data?.data.data.categoryResults.map(function (data) {
      return {
        value: data.categoryid,
        label: data.name,
      };
    }) || [];

  const Memberoptions =
    data?.data.data.memberResults.map(function (data) {
      return {
        value: data.user_id,
        label: data.fullname,
      };
    }) || [];

  const StatusOptions = [
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
  ];

  const combineOptions = (
    tags,
    members,
    inspections,
    options,
    Memberoptions1,
    StatusOptions,
    fromDate, // New addition for From Date
    thruDate // New addition for Thru Date
  ) => {
    let combinedOptions = [];
    // console.log("bagFilter:", members);
    // console.log("flatOptionsBags:", Memberoptions1.map(option => option.value));

    // Add date range filters first
    if (fromDate) {
      combinedOptions.push({
        value: fromDate,
        label: `From Date: ${fromDate}`,
        type: "Date",
      });
    }

    if (thruDate) {
      combinedOptions.push({
        value: thruDate,
        label: `Thru Date: ${thruDate}`,
        type: "Date",
      });
    }

    // Add other filters
    combinedOptions = [
      ...combinedOptions,
      ...options
        .filter((option) => tags.includes(option.value))
        .map((option) => ({ ...option, type: "Tag" })),

      ...Memberoptions1.filter((option) => members.includes(option.value)).map(
        (option) => ({ ...option, type: "Member" })
      ),
      ...StatusOptions.filter((option) =>
        inspections.includes(option.value)
      ).map((option) => ({ ...option, type: "Inspection" })),
    ];

    return combinedOptions;
  };

  const updateSelectedOptions = (
    tags,
    members,
    inspections,
    fromDate,
    thruDate
  ) => {
    const combinedOptions = combineOptions(
      tags,
      members,
      inspections,
      options,
      Memberoptions,
      StatusOptions,
      fromDate, // Pass in fromDate
      thruDate // Pass in thruDate
    );
    setSelectedOptions(combinedOptions);
  };

  //Input useStae
  useEffect(() => {
    const storedEventStatusFilter =
      localStorage.getItem("EventStatusFilters") || "[]";
    const storedFromDateFilter =
      localStorage.getItem("EventFromDateFilter") || "";
    const storedThruDateFilter =
      localStorage.getItem("EventThruDateFilter") || "";

    const storedEventCategoryFilter = (
      localStorage.getItem("EventCategoryFilters") || "[]"
    )
      .split(",")
      .filter(Boolean)
      .map(Number);

    const storedEventMemberFilter = (
      localStorage.getItem("EventMemberFilter") || "[]"
    )
      .split(",")
      .filter(Boolean)
      .map(Number);

    if (storedEventStatusFilter.length > 0) {
      setStatusFilter(storedEventStatusFilter);
    }

    if (storedEventCategoryFilter.length > 0) {
      setCategoryFilter(storedEventCategoryFilter);
    }

    if (storedEventMemberFilter.length > 0) {
      setMemberFilter(storedEventMemberFilter);
    }

    if (storedFromDateFilter) {
      setFromDateFilter(storedFromDateFilter);
    }

    if (storedThruDateFilter) {
      setThruDateFilter(storedThruDateFilter);
    }
  }, []);

  useEffect(() => {
    // Ensure that options are available before attempting to update selected options
    if (options.length && Memberoptions.length && StatusOptions.length) {
      // Only update if there's a change in the filters or the necessary options are loaded
      if (
        categoryFilter.length ||
        memberFilter.length ||
        statusFilter.length ||
        fromDateFilter.length ||
        thruDateFilter.length
      ) {
        updateSelectedOptions(
          categoryFilter,
          memberFilter,
          statusFilter,
          fromDateFilter, // Pass in fromDateFilter
          thruDateFilter // Pass in thruDateFilter
        );
      }
    }
  }, [
    categoryFilter,
    memberFilter,
    statusFilter,
    fromDateFilter,
    thruDateFilter,
    options.length, // Watch for when options are loaded
    Memberoptions.length, // Watch for when Memberoptions are loaded
    StatusOptions.length, // Watch for when NextInspectionOptions are loaded
  ]);

  const [trainingName, setTrainingName] = useState("");
  const [trainingDesc, setTrainingDesc] = useState("");

  const [trainer] = useState([]);

  const [newDate, setNewDate] = useState();

  const [status, setStatus] = useState("Active");

  //note

  const [category, setCategory] = useState("");

  const [eventType, setEventType] = useState("");

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const togglePopupFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const [errors, setErrors] = useState({});

  const validationRules = {
    trainingForm: {
      trainingName: "Please enter a name",
      category: "Please select a category",
      newDate: "Please select a date",
    },
    // Add more validation rules for other forms here
  };

  const validateForm = (values, rules) => {
    const newErrors = {};
    let isValid = true;

    Object.keys(rules).forEach((key) => {
      if (!values[key]) {
        newErrors[key] = rules[key];
        isValid = false;
      }
    });

    setErrors(newErrors);

    if (!isValid) {
      notify("Please fill out all required fields", "error");
    }

    return isValid;
  };

  const clearForm = () => {
    setLoading(false);
    setErrors({});
    setTrainingName("");
    setTrainingDesc("");
    setCategory("");
    setEventType("");
    setNewDate("");
    setStatus("Active");
  };

  const handleNewTrainingSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    if (loading) return; // Prevent double submission
    const formValues = {
      trainingName,
      category,
      newDate,
    };

    if (!validateForm(formValues, validationRules.trainingForm)) {
      return;
    }

    setLoading(true);

    try {
      //eslint-disable-next-line
      const response = await TrainingFinder.post("/newtraingevent", {
        training_name: trainingName,
        training_desc: trainingDesc,
        trainer: trainer,
        categoryid: category,
        eventType: eventType,
        date: newDate,
        status: status,
        currentUserSiteId,
      });

      newUpcomingPopupClose();
      clearForm();
      refetch();
      notify("Event created successfully!", "success"); // Show success notification
    } catch (err) {
      setLoading(false);
      notify("Failed to create event. Please try again.", "error"); // Show error notification
    }
  };

  const selecttraining = (id) => {
    history(`/training/${id}`);
  };

  const setCategoryFunc = (label) => {
    setCategory(label);
    setEventType("");
  };

  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => row.date1, {
      id: "date1",
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
      header: () => <div className="min-max-width">Date</div>,
    }),
    columnHelper.accessor((row) => row.training_name, {
      id: "training_name",
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
      header: () => <div className="min-max-width">Event Name</div>,
    }),
    columnHelper.accessor((row) => row.description, {
      id: "description",
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
      header: () => <div className="min-max-width">Description</div>,
    }),
    columnHelper.accessor((row) => row.category, {
      id: "category",
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
      header: () => <div className="min-max-width">Category</div>,
    }),
    columnHelper.accessor((row) => row.status, {
      id: "status",
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
      header: () => <div className="min-max-width">Status</div>,
    }),
    columnHelper.accessor((row) => row.attended, {
      id: "attended",
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
      header: () => <div className="min-max-width">Member Count</div>,
    }),
    columnHelper.accessor((row) => row.notecount, {
      id: "notecount",
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
      header: () => <div className="min-max-width">Note Count</div>,
    }),
    columnHelper.accessor((row) => row.itemcount, {
      id: "itemcount",
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
      header: () => <div className="min-max-width">Item Count</div>,
    }),
    columnHelper.accessor((row) => row.photocount, {
      id: "photocount",
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => selecttraining(info.row.original.id)}
        >
          {info.getValue()}
        </div>
      ),
      header: () => <div className="min-max-width">Photos</div>,
    }),
  ];

  const initialPage = parseInt(localStorage.getItem("InventoryPage")) || 0;

  const table = useReactTable({
    data: eventList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageIndex: initialPage, pageSize: 25 } }, // Set initial page size and page index
  });

  const pdfColumns = [
    { header: "Date", accessor: (row) => row.date1 },
    { header: "Event Name", accessor: (row) => row.training_name },
    { header: "Description", accessor: (row) => row.description },
    { header: "Category", accessor: (row) => row.category },
    { header: "Status", accessor: (row) => row.status },
    { header: "Item Count", accessor: (row) => row.itemcount },
    { header: "Members", accessor: (row) => row.members.join(", ") },
    {
      header: "Notes",
      accessor: (row) =>
        row.notes
          .map(
            (note) =>
              `Note: ${note.note} (Category: ${
                note.category
              }, Members: ${note.members.join(", ")})`
          )
          .join("\n"),
    },
  ];

  const handleClearFilters = () => {
    setCategoryFilter([]);
    setStatusFilter([]);
    setMemberFilter([]);
    setFromDateFilter("");
    setThruDateFilter("");
    localStorage.removeItem("EventCategoryFilters");
    localStorage.removeItem("EventStatusFilters");
    localStorage.removeItem("EventMemberFilter");
    localStorage.removeItem("EventFromDateFilter");
    localStorage.removeItem("EventThruDateFilter");
    localStorage.removeItem("EventPage");
    setSelectedOptions([]);

    refetch();
  };

  const handleChange = (e) => {
    const categorySearch = Array.isArray(e) ? e.map((x) => x.value) : [];
    localStorage.setItem("EventCategoryFilters", categorySearch); // Store as JSON string
    localStorage.setItem("EventPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setCategoryFilter(categorySearch); // Update the state
    refetch(); // Refetch data
  };

  const handleChangeMember = (e) => {
    const MemberFilter2 = Array.isArray(e) ? e.map((x) => x.value) : [];
    localStorage.setItem("EventMemberFilter", MemberFilter2); // Store as JSON string
    localStorage.setItem("EventPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setMemberFilter(MemberFilter2); // Update the state
    refetch(); // Refetch data
  };

  // Get default values for the Select component from the state
  const defaultValues = options.filter((option) =>
    categoryFilter.includes(option.value)
  );

  const handleStatusChange = (e) => {
    const statusSearch = Array.isArray(e) ? e.map((x) => x.value) : [];
    localStorage.setItem("EventStatusFilters", statusSearch); // Store as JSON string
    localStorage.setItem("EventPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setStatusFilter(statusSearch); // Update the state
    refetch(); // Refetch data
  };

  // Get default values for the Select component from the state
  const StatusdefaultValues = StatusOptions.filter((option) =>
    statusFilter.includes(option.value)
  );

  const MemberdefaultValues = Memberoptions.filter((option) =>
    memberFilter.includes(option.value)
  );

  const handleFromDateChange = (e) => {
    const selectedFromDate = e.target.value;
    setFromDateFilter(selectedFromDate);
    localStorage.setItem("EventFromDateFilter", selectedFromDate);

    if (
      !thruDateFilter ||
      new Date(thruDateFilter) < new Date(selectedFromDate)
    ) {
      setThruDateFilter(selectedFromDate);
      localStorage.setItem("EventThruDateFilter", selectedFromDate);
    }

    refetch();
  };

  const handleThruDateChange = (e) => {
    const selectedThruDate = e.target.value;
    setThruDateFilter(selectedThruDate);
    localStorage.setItem("EventThruDateFilter", selectedThruDate);

    if (
      !fromDateFilter ||
      new Date(fromDateFilter) > new Date(selectedThruDate)
    ) {
      setFromDateFilter(selectedThruDate);
      localStorage.setItem("EventFromDateFilter", selectedThruDate);
    }

    refetch();
  };

  return (
    <div className="flex flex-col">
      <div className=" top-[64px] bg-white border-b shadow-sm">
        <div className="filter-search-toggle-container">
          <div className={`filter-search-container`}>
            <button
              className="group flex items-center font-medium text-gray-700 rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={togglePopupFilter}
            >
              <AdjustmentsHorizontalIcon
                aria-hidden="true"
                className=" mr-2 size-5 flex-none text-gray-400 group-hover:text-gray-500"
              />
              Filters
            </button>

            <PDFReportTemplate
              data={eventList} // Pass the entire eventList
              reportDateRange="2024-01-01 to 2024-12-31"
              category="Inventory"
              members={["John Doe", "Jane Smith"]}
              filters={selectedOptions}
              title="Event Report"
              logoUrl={`${PublicFolder.baseURL}LogonIcon.png`}
              pdfColumns={pdfColumns}
              includeDateRange={false} // Pass these flags based on your requirement
              includeCategory={false}
              includeMembers={false}
              includeFilters={true}
            />

            <button
              className="group flex items-center font-medium text-gray-700 rounded bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={newUpcomingPopup}
            >
              New Event
            </button>
            <div className="selected-options-display">
              <div className="selected-options-row">
                {selectedOptions.map((option, index) => (
                  <div key={index} className="selected-option">
                    {option.label}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto border-b z-1 border-gray-200 print:border-0 print:shadow-none max-h-[calc(100vh_-_290px)] sm:max-h-[calc(100vh_-_290px)]">
        {eventList ? (
          <>
            <table>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="flex items-center justify-between bg-white px-2 py-2 sm:px-6 sm:rounded-b-lg">
        <Pagination table={table} />
      </div>

      {isOpen7 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewTrainingSubmit}>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Event Name">
                    <TextareaAutosize
                      onChange={(e) => setTrainingName(e.target.value)}
                      className="NewInput"
                      maxRows={10}
                      type="text"
                      placeholder=""
                      required
                    />
                    {errors.trainingName && (
                      <div className="errorInputLabel">
                        {errors.trainingName}
                      </div>
                    )}
                  </label>
                  <label
                    className="NewInputLabel"
                    data-domain="Event Description"
                  >
                    <TextareaAutosize
                      onChange={(e) => setTrainingDesc(e.target.value)}
                      className="NewInput"
                      maxRows={10}
                      type="text"
                      placeholder=""
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Category">
                    <Select
                      options={options}
                      onChange={(e) => setCategoryFunc(e.value)}
                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      required
                      maxMenuHeight={150}
                    />
                    {errors.category && (
                      <div className="errorInputLabel">{errors.category}</div>
                    )}
                  </label>
                  <label className="NewInputLabel" data-domain="Status">
                    <Select
                      options={StatusOptions}
                      defaultValue={StatusOptions.find(
                        (StatusOptions) => StatusOptions.value === "Active"
                      )}
                      onChange={(e) => setStatus(e.value)}
                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      required
                      maxMenuHeight={150}
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Date">
                    <input
                      className="NewInput"
                      type="date"
                      placeholder=""
                      onChange={(e) => setNewDate(e.target.value)}
                      required
                    />
                    {errors.newDate && (
                      <div className="errorInputLabel">{errors.newDate}</div>
                    )}
                  </label>
                </div>
              </form>
            </>
          }
          handleClose={newUpcomingPopupClose}
          isOpen={isOpen7}
          handleSubmit={handleNewTrainingSubmit}
          showSubmitButton={true}
          headerText={<b>New Event</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
          loading={loading}
        />
      )}

      {isFilterOpen && (
        <PopupWeb
          content={
            <div>
              <button
                className="close-button text-sm"
                onClick={handleClearFilters}
              >
                Clear all
              </button>
              <div className="inventorySelectHolder">
                <div className="inventorySelect1">
                  <label className="NewInputLabel123" data-domain="From Date">
                    <input
                      type="date"
                      className="NewInput"
                      onChange={handleFromDateChange}
                      value={fromDateFilter} // State variable for the from date
                    />
                  </label>
                </div>
                {/* Thru Date Filter */}
                <label className="NewInputLabel123" data-domain="Thru Date">
                  <input
                    type="date"
                    className="NewInput"
                    min={fromDateFilter} // Disable dates earlier than the From Date
                    onChange={handleThruDateChange}
                    value={thruDateFilter} // State variable for the thru date
                  />
                </label>
              </div>
              <label className="NewInputLabel123" data-domain="Event Category">
                <Select
                  isMulti
                  options={options}
                  onChange={handleChange}
                  value={defaultValues}
                  className="filter-dropdown"
                  classNamePrefix="Select"
                  maxMenuHeight={150}
                />
              </label>

              <label className="NewInputLabel123" data-domain="Status">
                <Select
                  isMulti
                  options={StatusOptions}
                  onChange={handleStatusChange}
                  value={StatusdefaultValues}
                  className="filter-dropdown"
                  classNamePrefix="Select"
                  maxMenuHeight={150}
                />
              </label>

              <label className="NewInputLabel123" data-domain="Member Attended">
                <Select
                  isMulti
                  options={Memberoptions}
                  onChange={handleChangeMember}
                  value={MemberdefaultValues}
                  className="filter-dropdown"
                  classNamePrefix="Select"
                  maxMenuHeight={150}
                />
              </label>
            </div>
          }
          handleClose={togglePopupFilter}
          isOpen={isFilterOpen}
          showSubmitButton={false}
          headerText={<b>Filters</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {isOpen8 && (
        <PopupWeb
          content={
            <>
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={newUpcomingPopup}>
                    <b>New Event</b>
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={newSelectPopup}
          isOpen={isOpen8}
          showSubmitButton={false}
          headerText={<b>New Event</b>}
        />
      )}
    </div>
  );
};

export default EventHeader;
