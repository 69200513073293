import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import TrainingFinder from "../../apis/TrainingFinder";
import { TrainingInfoContext } from "../../context/TrainingInfoContext";
import Header from "../Header/Header";
import ClipLoader from "react-spinners/ClipLoader";

import TrainingInfoItems from ".//TrainingInfoItems";
import TrainingInfoMembers from ".//TrainingInfoMembers";
import TrainingInfoPictures from ".//TrainingInfoPictures";

import PopupWeb from "../Popup/PopupNew";
import { MdArrowBackIosNew } from "react-icons/md";

import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import { useAuth } from "../../context/AuthContext";
import TextareaAutosize from "react-textarea-autosize";
import { notify } from "../../ToastNotifier";
import Select from "react-select";

const TrainingInfoHeader = () => {
  const { id } = useParams();
  //reload page
  const { currentUserSiteId } = useAuth();

  const history = useNavigate();

  const [training, setTraining] = useState("");
  const [trainingStatus, setTrainingStatus] = useState("");

  const { setTrainingMembers, trainingMembers } =
    useContext(TrainingInfoContext);
  const { setTrainingPhotos } = useContext(TrainingInfoContext);
  const { setTrainingItems } = useContext(TrainingInfoContext);
  const { setMemberList } = useContext(TrainingInfoContext);
  const { trainingId, setTrainingId } = useContext(TrainingInfoContext);
  const { trainingNotes, setTrainingNotes } = useContext(TrainingInfoContext);
  const [categoryList, setCategoryList] = useState([]);

  const {
    photoCount,
    setPhotoCount,
    trainingItemsCount,
    setTrainingItemsCount,
    memberAttendingCount,
    setMemberAttendingCount,
    photoPopupOpen,
    setPhotoPopupOpen,
    itemPopupOpen,
    setItemPopupOpen,
    memberPopupOpen,
    setMemberPopupOpen,
    setApparatusList,
  } = useContext(TrainingInfoContext);

  const handleMemberPopup = () => {
    setMemberPopupOpen(!memberPopupOpen);
  };

  const handleItemPopup = () => {
    setItemPopupOpen(!itemPopupOpen);
  };

  const handlePhotoPopup = () => {
    setPhotoPopupOpen(!photoPopupOpen);
  };

  //Popup create new events
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const detailsPopup = () => {
    setIsOpenDetails(!isOpenDetails);
  };

  const [noteCount, setNoteCount] = useState("0");

  useEffect(() => {
    const fetchData = async (date) => {
      try {
        const response = await TrainingFinder.get("/trainingDetails", {
          params: {
            trainingId: id,
            currentUserSiteId,
          },
        });

        setTraining(response.data.data.trainingInfo);
        setTrainingName(response.data.data.trainingInfo[0].training_name);
        setTrainingDesc(response.data.data.trainingInfo[0].description);
        setTrainingStatus(response.data.data.trainingInfo[0].status);
        setTrainingId(response.data.data.trainingInfo[0].id);
        setCategory(response.data.data.trainingInfo[0].categoryid);
        setCategoryList(response.data.data.categoryList);
        setTrainingMembers(response.data.data.trainingMembers);
        setMemberAttendingCount(response.data.data.trainingMembers.length);

        setTrainingPhotos(response.data.data.trainingPhotos);
        setPhotoCount(response.data.data.trainingPhotos.length);

        setTrainingItems(response.data.data.trainingItems);
        setTrainingItemsCount(response.data.data.trainingItems.length);

        setMemberList(response.data.data.memberNames);
        setApparatusList(response.data.data.apparatusList);

        //setDate(new Date(response.data.data.trainingDetails[0].unformateddate));

        setDate(
          response.data.data.trainingInfo[0].date === null
            ? ""
            : new Date(response.data.data.trainingInfo[0].date)
                .toISOString()
                .substring(0, 10)
        );

        setTrainingNotes(response.data.data.trainingnotes);
        setNoteCount(response.data.data.trainingnotes.length);
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    } // eslint-disable-next-line
  }, [currentUserSiteId]);

  const [loading, setLoading] = useState(false);

  const [trainingName, setTrainingName] = useState("");
  const [trainingDesc, setTrainingDesc] = useState("");

  const [trainer] = useState("");

  const [category, setCategory] = useState("");

  const [eventType, setEventType] = useState("");

  const [date, setDate] = useState("");

  //Popup Training Details
  const [isOpen2, setIsOpen2] = useState(false);
  const trainingDetailsPopupClose = () => {
    setIsOpen2(!isOpen2);
  };
  const StatusOptions = [
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
  ];

  let options = categoryList.map(function (data) {
    return {
      value: data.categoryid,
      label: data.name,
      id: data.categoryid,
    };
  });

  const [errors, setErrors] = useState({});

  const validationRules = {
    trainingForm: {
      trainingName: "Please enter a name",
      category: "Please select a category",
      date: "Please select a date",
    },
    noteForm: {
      trainingNewNote: "Please enter a text",
      trainingNewNoteSubjectId: "Please select a category",
    },
    // Add more validation rules for other forms here
  };

  const validateForm = (values, rules) => {
    const newErrors = {};
    let isValid = true;

    Object.keys(rules).forEach((key) => {
      if (!values[key]) {
        newErrors[key] = rules[key];
        isValid = false;
      }
    });

    setErrors(newErrors);

    if (!isValid) {
      notify("Please fill out all required fields", "error");
    }

    return isValid;
  };

  const clearForm = () => {
    setLoading(false);
    setErrors({});
  };


  const handleEditTrainingSubmit = async (e) => {
   e.preventDefault();
    if (loading) return; // Prevent double submission
    const formValues = {
      trainingName,
      category,
      date,
    };

    if (!validateForm(formValues, validationRules.trainingForm)) {
      return;
    }

    setLoading(true);
    try {

      const response = await TrainingFinder.post("/updateCompletedTraining", {
        training_name: trainingName,
        training_desc: trainingDesc,
        trainer: trainer,
        categoryid: category,
        event_type: eventType,
        date: date,
        trainingId,
        currentUserSiteId,
        trainingStatus,
      });

      //  // console.log(response.data.data.training);
      setTraining(response.data.data.trainingInfo);

      setDate(
        response.data.data.trainingInfo[0].unformateddate === null
          ? ""
          : new Date(response.data.data.trainingInfo[0].unformateddate)
              .toISOString()
              .substring(0, 10)
      );

      trainingDetailsPopupClose();
      clearForm();
      notify("Event update successfully!", "success"); // Show success notification
    } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to update event. Please try again.", "error"); // Show error notification
    }
  };;


  //Popup
  const [deletePopup, setDeletePopup] = useState(false);
  const toggleDeletePopup = () => {
    setDeletePopup(!deletePopup);
    setIsOpen2(!isOpen2);
  };
  const toggleDeletePopup2 = () => {
    setDeletePopup(!deletePopup);
  };

  //Delete training
  const handleDelete = async (e) => {
    //stop the page from reloading
    e.preventDefault();
console.log("fdlksmfjlksdnmfjklsdnmfjdks")
    try {
      // eslint-disable-next-line
      const response = await TrainingFinder.post("/deleteCompletedTraining", {
        trainingId,
        currentUserSiteId,
      });

      history("/events");
      notify("Event deleted successfully!", "success"); // Show success notification
    } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to delete event. Please try again.", "error"); // Show error notification
    }
  };

  const setCategoryFunc = (label) => {
    setCategory(label);
    setEventType("");
  };

  const [isOpenNoteRemove, setIsOpenNoteRemove] = useState(false);

  const toggleNoteRemove = (trainingnote_id) => {
    setTrainingNewNoteId(trainingnote_id);
    setIsOpenNoteRemove(!isOpenNoteRemove);
    setIsOpenDetails(!isOpenDetails);
  };

  const [isOpenDetailsForm, setIsOpenDetailsForm] = useState(false);
  const detailsFormPopup = () => {
    setIsOpenDetails(!isOpenDetails);
    setIsOpenDetailsForm(!isOpenDetailsForm);
    setTrainingNewNoteId("");
    setTrainingNewNote("");
    setAddNoteMember([]);

  };

  const [trainingNewNoteId, setTrainingNewNoteId] = useState("");
  const [trainingNewNote, setTrainingNewNote] = useState("");
  const [trainingNewMembers, setTrainingNewMembers] = useState([]);
  const [trainingNoteSubject, seTrainingNoteSubject] = useState("");
  const [trainingNewNoteSubjectId, setTrainingNewNoteSubjectId] = useState("");
  const [addNoteMember, setAddNoteMember] = useState([]);


  const editSubject = async (
    trainingnote_id,
    subject,
    note,
    members,
    categoryId
  ) => {
    console.log(members);
    //Delete training
    try {
      setAddNoteMember(
        members.map(function (data2) {
          return {
            value: data2.user_id,
            label: data2.firstname + " " + data2.lastname,
            id: data2.user_id,
          };
        })
      );
      setTrainingNewMembers(
        members.map(function (data2) {
          return {
            value: data2.user_id,
            label: data2.firstname + " " + data2.lastname,
          };
        })
      );
      setTrainingNewNoteId(trainingnote_id);
      seTrainingNoteSubject(subject);
      setTrainingNewNoteSubjectId(categoryId);
      setTrainingNewNote(note);
      setIsOpenDetails(!isOpenDetails);
      setIsOpenDetailsForm(!isOpenDetailsForm);
    } catch (err) {
      console.log(err);
    }
  };

  const trailingActions2 = (trainingnote_id) => (
    <TrailingActions>
      <SwipeAction
        onClick={() => toggleNoteRemove(trainingnote_id)}
        //onClick={() => // console.log("lkfalkkld")}
      >
        <div className="unassignDiv2">
          <b>Remove</b>
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  const handleNoteDelete = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);

      const response = await TrainingFinder.post("/deleteCompletedNote", {
        trainingId,
        trainingNewNoteId,
        currentUserSiteId,
      });
      setTrainingNotes(response.data.data.trainingnotes);
        setNoteCount(response.data.data.trainingnotes.length);

      setLoading(false);
      toggleNoteRemove();
      notify("Removed note successfully!", "success"); // Show success notification
    } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to removed note. Please try again.", "error"); // Show error notification
    }
  };

  const handleNoteSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    if (loading) return; // Prevent double submission
    const formValues = {
      trainingNewNote,
      trainingNewNoteSubjectId,
    };

    if (!validateForm(formValues, validationRules.noteForm)) {
      return;
    }

    setLoading(true);
    try {

      if (trainingNewNoteId === "") {

        const response = await TrainingFinder.post("/addCompletedNote", {
          trainingId,
          trainingNewNoteSubjectId,
          trainingNewNote,
          currentUserSiteId,
          addNoteMember,
        });
        setLoading(false);
        detailsFormPopup(); 
        setTrainingNotes(response.data.data.trainingnotes);
        setNoteCount(response.data.data.trainingnotes.length);
      } else {
        console.log("test");

        const response = await TrainingFinder.post("/updateCompletedNote", {
          trainingId,
          trainingNewNoteId,
          trainingNewNoteSubjectId,
          trainingNewNote,
          currentUserSiteId,
          addNoteMember,
        });
        detailsFormPopup();
        setTrainingNotes(response.data.data.trainingnotes);
        setNoteCount(response.data.data.trainingnotes.length);

      }
      setLoading(false);
      notify("Saved note successfully!", "success"); // Show success notification
    } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to save note. Please try again.", "error"); // Show error notification
    }
  };

  const sideBar = document.getElementById("sidebar");

  return (
    <>
      <div
        id="topbar"
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }
      >
        <div className="goBackDiv" onClick={() => history(-1)}>
          <div className="goBackDivarrow">
            <MdArrowBackIosNew />
          </div>
          <div className="goBackDivtxt">Back</div>
        </div>
        <Header content={<b>Event Information</b>} />
        <div className="addEdit" onClick={trainingDetailsPopupClose}>
          Edit
        </div>
      </div>

      {training &&
        training.map((data, i) => {
          return (
            <div key={i} className="itemPageheader">
              <div className="trainingHeaderName">
                <b>
                  {data.training_name} {data.date}
                </b>
              </div>

              <div className="trainingPageSideRightContainer">
                <div className="itemPageAmountContainer">
                  <div className="itemPageAmountName">
                    <b>Description</b>
                  </div>
                  <div className="itemPageAmountNumber22">
                    <b>{data.description}</b>
                  </div>
                </div>
                <div className="itemPageAmountContainer">
                  <div className="itemPageAmountName">
                    <b>Category</b>
                  </div>
                  <div className="itemPageAmountNumber22">
                    <b>{data.category}</b>
                  </div>
                </div>
                <div className="itemPageAmountContainer2">
                  <div className="itemPageAmountName">
                    <b>Status</b>
                  </div>
                  <div className="itemPageAmountNumber22">
                    <b>{data.status}</b>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

      {training.length > 0 ? (
        <>
          <div className="profileBoxContainer2">
            <div className="profileBoxHolder2" onClick={handleMemberPopup}>
              <div className="profileBoxName1">
                <b>Members</b>
              </div>
              <div className="profileBoxName">
                <b>{memberAttendingCount}</b>
              </div>
            </div>
            <div className="profileBoxHolder2" onClick={handleItemPopup}>
              <div className="profileBoxName1">
                <b>Items</b>
              </div>
              <div className="profileBoxName">
                <b>{trainingItemsCount}</b>
              </div>
            </div>
            <div className="profileBoxHolder2" onClick={detailsPopup}>
              <div className="profileBoxName1">
                <b>Notes</b>
              </div>
              <div className="profileBoxName">
                <b>{noteCount}</b>
              </div>
            </div>
            <div className="profileBoxHolder2" onClick={handlePhotoPopup}>
              <div className="profileBoxName1">
                <b>Photos</b>
              </div>
              <div className="profileBoxName">
                <b>{photoCount}</b>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="trainingEmptyContainer">
            <div className="trainingEmptyContainer2">
              <b>Select a event or start a new one</b>
            </div>
          </div>
        </>
      )}

      {memberPopupOpen && <TrainingInfoMembers />}
      {itemPopupOpen && <TrainingInfoItems />}
      {photoPopupOpen && <TrainingInfoPictures />}

      {isOpen2 && (
        <PopupWeb
          content={
            <>
              {training.length > 0 ? (
                training.map((data, i) => {
                  console.log(data);
                  return (
                    <div key={i}>
                      <form onSubmit={handleEditTrainingSubmit}>
              

                        <div className="text-center p-3">
                          <ClipLoader
                            loading={loading}
                            className="override"
                            size={35}
                          />
                        </div>

                        <div className="popupContainer">
                          <label className="NewInputLabel" data-domain="Name">
                            <TextareaAutosize
                              onChange={(e) => setTrainingName(e.target.value)}
                              className="NewInput"
                              maxRows={10}
                              type="text"
                              placeholder=""
                              value={trainingName}
                              required
                            />
                          </label>

                          <label
                            className="NewInputLabel"
                            data-domain="Description"
                          >
                            <TextareaAutosize
                              onChange={(e) => setTrainingDesc(e.target.value)}
                              className="NewInput"
                              maxRows={10}
                              type="text"
                              placeholder=""
                              value={trainingDesc}
                            />
                          </label>

                          <label
                            className="NewInputLabel"
                            data-domain="Category"
                          >
                            <Select
                              options={options}
                              defaultValue={options.find(
                                (option) => option.value === category
                              )}
                              onChange={(e) => setCategoryFunc(e.value)}
                              name="addMembers"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              required
                              maxMenuHeight={150}

                            />
                            {errors.category && (
                              <div className="errorInputLabel">
                                {errors.category}
                              </div>
                            )}
                          </label>

                  
                          <label
                            className="NewInputLabel"
                            data-domain="Select Status"
                          >
                            <Select
                              options={StatusOptions}
                              defaultValue={StatusOptions.find(
                                (StatusOptions) =>
                                  StatusOptions.value === trainingStatus
                              )}
                              onChange={(e) => setTrainingStatus(e.value)}
                              name="addMembers"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              required
                              maxMenuHeight={150}

                            />
                          </label>

                          <label
                            className="NewInputLabel"
                            data-domain="Start Date"
                          >
                            <input
                              className="NewInput"
                              type="date"
                              placeholder=""
                              value={date}
                              onChange={(e) => setDate(e.target.value)}
                              required
                            />
                          </label>
                        </div>
                        <div className="editApparatusBtnContainer">
                          <div
                            className="editApparatusBtn"
                            onClick={toggleDeletePopup}
                          >
                            <b>Delete</b>
                          </div>
                        </div>
                      </form>
                    </div>
                  );
                })
              ) : (
                <>Select a event first</>
              )}
            </>
          }

          handleClose={trainingDetailsPopupClose}
          isOpen={isOpen2}
          handleSubmit={handleEditTrainingSubmit}
          showSubmitButton={true}
          headerText={     <b> Edit Event Information</b>}
          submitButtonText={loading ? "Loading..." : "Save"} 

        />
      )}

      {isOpenDetails && (
        <PopupWeb
          content={
            <>
             
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>

              <div className="popupContainer">
                {trainingNotes.length > 0 ? (
                  trainingNotes.map((data, i) => {
                    console.log(data);
                    return (
                      <div key={i}>
                        <SwipeableList>
                          <SwipeableListItem
                            trailingActions={trailingActions2(
                              data.trainingnote_id
                            )}
                          >
                            <div className="noteListHolder">
                              <div className="noteListSubjectHolder">
                                <div className="noteListSubject">
                                  <b>{data.subject}</b>
                                </div>
                                <div
                                  className="noteSubmitItemBtn"
                                  onClick={() =>
                                    editSubject(
                                      data.trainingnote_id,
                                      data.subject,
                                      data.note,
                                      data.trainingnotemembers,
                                      data.categoryid
                                    )
                                  }
                                >
                                  <b>Edit</b>
                                </div>
                              </div>

                              {data.trainingnotemembers[0].firstname ===
                              null ? (
                                <></>
                              ) : (
                                <div className="noteListNote">
                                  <div className="notelistMembersTitle">
                                    <b>Members</b>
                                  </div>
                                  <div
                                    className="notelistMembers"
                                    id={data.trainingnote_id}
                                  >
                                    {data.trainingnotemembers.map(
                                      (data2, i) => (
                                        <div key={i} className="4">
                                          <div className="4">
                                            {data2.firstname} {data2.lastname}
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                              <div className="noteListNote">
                                <div className="notelistMembersTitle">
                                  <b>Notes</b>
                                </div>
                                <div className="notelistMembers">
                                  {data.note}
                                </div>
                              </div>
                            </div>
                          </SwipeableListItem>
                        </SwipeableList>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </>
          }

          
          handleClose={detailsPopup}
          isOpen={isOpenDetails}
          handleSubmit={detailsFormPopup}
          showSubmitButton={true}
          headerText={  <b>Event Notes</b>}
          submitButtonText={loading ? "Loading..." : "Add"} 

        />
      )}

      {isOpenDetailsForm && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNoteSubmit}>
            

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">

                  <label className="NewInputLabel" data-domain="Category">
                    {trainingNoteSubject === "" ? (
                      <Select
                        options={categoryList.map(function (data2) {
                          return {
                            value: data2.categoryid,
                            label: data2.name,
                          };
                        })}
                        onChange={(event) =>
                          setTrainingNewNoteSubjectId(event.value)
                        }
                        name="addMembers"
                        className="mb-3"
                        isSearchable={false}
                        required
                        maxMenuHeight={150}

                      />
                    ) : (
                      <Select
                        options={categoryList.map(function (data2) {
                          return {
                            value: data2.categoryid,
                            label: data2.name,
                          };
                        })}
                        onChange={(event) =>
                          setTrainingNewNoteSubjectId(event.value)
                        }
                        defaultValue={{
                          label: trainingNoteSubject,
                          value: trainingNewNoteSubjectId,
                        }}
                        name="addMembers"
                        className="mb-3"
                        isSearchable={false}
                        maxMenuHeight={150}

                      />
                    )}

{errors.trainingNewNoteSubjectId && (
                      <div className="errorInputLabel">
                        {errors.trainingNewNoteSubjectId}
                      </div>
                    )}
                    
                  </label>

                  <label
                    className="NewInputLabel"
                    data-domain="Select Member Optional"
                  >
                    {trainingNewMembers.length === 0 ||
                    trainingNewMembers[0].value === null ? (
                      <Select
                        options={trainingMembers.map(function (data2) {
                          return {
                            value: data2.user_id,
                            label: data2.firstname + " " + data2.lastname,
                          };
                        })}
                        onChange={(event) => setAddNoteMember(event)}
                        name="addMembers"
                        className="mb-3"
                        isSearchable={false}
                        isMulti
                        maxMenuHeight={150}

                      />
                    ) : (
                      <Select
                        options={trainingMembers.map(function (data2) {
                          return {
                            value: data2.user_id,
                            label: data2.firstname + " " + data2.lastname,
                          };
                        })}
                        onChange={(event) => setAddNoteMember(event)}
                        defaultValue={trainingNewMembers.map(function (e, i) {
                          return { value: e.value, label: e.label };
                        })}
                        name="addMembers"
                        className="mb-3"
                        isSearchable={false}
                        isMulti
                        maxMenuHeight={150}

                      />
                    )}
                  </label>

                  <label className="NewInputLabel" data-domain="Text">
                    <TextareaAutosize
                      onChange={(e) => setTrainingNewNote(e.target.value)}
                      className="NewInput"
                      maxRows={10}
                      type="text"
                      placeholder=""
                      value={trainingNewNote}
                    />
                             {errors.trainingNewNote && (
                      <div className="errorInputLabel">
                        {errors.trainingNewNote}
                      </div>
                    )}

                  </label>
                </div>
              </form>
            </>
          }

          handleClose={detailsFormPopup}
          isOpen={isOpenDetailsForm}
          handleSubmit={handleNoteSubmit}
          showSubmitButton={true}
          headerText={   <b>Edit Event Note </b>}
          submitButtonText={loading ? "Loading..." : "Save"} 
          loading={loading}

        />
      )}

      {isOpenNoteRemove && (
        <PopupWeb
          content={
            <>
        

              <div className="deleteInfoApparatusContainer">
                <b>This will delete the current note</b>
              </div>

        
            </>
          }

          handleClose={toggleNoteRemove}
          isOpen={isOpenNoteRemove}
          handleSubmit={handleNoteDelete}
          showSubmitButton={true}
          headerText={  <b>Remove Note</b>}
          submitButtonText={loading ? "Loading..." : "Remove"} 
        />
      )}

      {deletePopup && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>Delete - {trainingName} </b>
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>This event will no longer be available</b>
              </div>

      
            </>
          }

          
          handleClose={toggleDeletePopup2}
          isOpen={deletePopup}
          handleSubmit={handleDelete}
          showSubmitButton={true}
          headerText={     <b>Delete - {trainingName} </b>}
          submitButtonText={loading ? "Loading..." : "Delete"} 
        />
      )}
    </>
  );
};

export default TrainingInfoHeader;
