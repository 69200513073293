"use client";

import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
} from "@headlessui/react";
import {
  Bars3Icon,
  Cog6ToothIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import LogonLogo from "../../PublicPhotos/LogonIcon.png";

// React Router imports
import { Outlet, NavLink } from "react-router-dom";

import { BsCalendar2Event } from "react-icons/bs";
import { MdOutlineSpaceDashboard, MdQrCodeScanner } from "react-icons/md";
import { AiOutlineAppstoreAdd, AiOutlineTool } from "react-icons/ai";

import { TbReportAnalytics, TbArrowsDownUp } from "react-icons/tb";

import { BsCardChecklist } from "react-icons/bs";
import { IoBagAddOutline } from "react-icons/io5";

import { MdAdminPanelSettings } from "react-icons/md"; // From Material Design Icons
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

import { usePageTitle } from "./PageTitleContext";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const navigation = [
    {
      name: "Dashboard",
      href: "/",
      icon: MdOutlineSpaceDashboard,
      title: "Dashboard",
    },
    {
      name: "Inventory",
      href: "/inventory",
      icon: AiOutlineTool,
      title: "Inventory Management",
    },
    {
      name: "Events",
      href: "/events",
      icon: BsCalendar2Event,
      title: "Events",
    },
    {
      name: "Locations",
      href: "/location",
      icon: AiOutlineAppstoreAdd,
      title: "Location List",
    },
    {
      name: "Bags",
      href: "/bags",
      icon: IoBagAddOutline,
      title: "Bag Information",
    },
    {
      name: "Checklist",
      href: "/checklistWeb",
      icon: BsCardChecklist,
      title: "Checklists",
    },
    { name: "QR", href: "/qr", icon: MdQrCodeScanner, title: "QR Scanner" },
    {
      name: "Reports",
      href: "/reports",
      icon: TbReportAnalytics,
      title: "Report Center",
    },
    {
      name: "Admin",
      href: "/admin",
      icon: MdAdminPanelSettings,
      title: "Admin Tools",
    },
  ];
 /* const teams = [
    { id: 1, name: "Heroicons", href: "#", initial: "H", current: false },
    { id: 2, name: "Tailwind Labs", href: "#", initial: "T", current: false },
    { id: 3, name: "Workcation", href: "#", initial: "W", current: false },
  ];*/
  const userNavigation = [
    { name: "My Profile", href: "/profile", title: "Your Profile" },
    {
      name: "Sign out",
     href: "#", 
      title: "Sign Out",
      onClick: handleLogout, // << Pass the function reference here
    },
  ];

  const history = useNavigate();

  const {
    logout,
    currentLastName,
    currentFirstName,
    currentUserSiteName,
  } = useAuth();

  async function handleLogout() {
    
    try {
      await logout();
      history("/login");
    } catch (eer) {
      // console.log(eer);
    }
  }

  const [sidebarOpen, setSidebarOpen] = useState(false);




  const { pageTitle } = usePageTitle();


  // Dynamically set the page title
 // const pageTitle =
 //   location.pathname === "/sitesettings"
 //     ? "Site Settings" // Explicit title for Site Settings
  //    : location.pathname === "/site"
  ///    ? "Site List" // Explicit title for Site List
 //     : currentNavItem
  //    ? currentNavItem.title || currentNavItem.name
 ////     : "Not Found";

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className="relative z-7 lg:hidden"
        >
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      aria-hidden="true"
                      className="size-6 text-white"
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                <div className="items-center border-b border-gray-200 bg-white">
                  <NavLink
                    className="flex h-16 shrink-0 items-center"
                    to="/site"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <img
                      src={LogonLogo}
                      alt="Did Not Load"
                      className="h-8 w-auto"
                    />

                    <div className="logo-name__name">
                      <b>{currentUserSiteName}</b>
                    </div>

                    <div className="px-6">
                      <TbArrowsDownUp />
                    </div>
                  </NavLink>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul  className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <NavLink
                              to={item.href}
                              end
                              className={({ isActive }) =>
                                classNames(
                                  isActive
                                    ? "bg-gray-50 text-indigo-600"
                                    : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                  "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold"
                                )
                              }
                              onClick={() => setSidebarOpen(false)} // Closes sidebar on click
                            >
                              {({ isActive }) => (
                                <>
                                  <item.icon
                                    aria-hidden="true"
                                    className={classNames(
                                      isActive
                                        ? "text-indigo-600"
                                        : "text-gray-400 group-hover:text-indigo-600",
                                      "size-6 shrink-0"
                                    )}
                                  />
                                  {item.name}
                                </>
                              )}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </li>
                    {/*   <li>
                      <div className="text-xs/6 font-semibold text-gray-400">
                        Your teams
                      </div>
                      <ul role="list" className="-mx-2 mt-2 space-y-1">
                        {teams.map((team) => (
                          <li key={team.name}>
                            <a
                              href={team.href}
                              className={classNames(
                                team.current
                                  ? "bg-gray-50 text-indigo-600"
                                  : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold"
                              )}
                            >
                              <span
                                className={classNames(
                                  team.current
                                    ? "border-indigo-600 text-indigo-600"
                                    : "border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600",
                                  "flex size-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium"
                                )}
                              >
                                {team.initial}
                              </span>
                              <span className="truncate">{team.name}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>*/}
                <li className="mt-auto">
                  <NavLink
                    to="/sitesettings"
                                                  onClick={() => setSidebarOpen(false)} // Closes sidebar on click
   
                    className={({ isActive }) =>
                      `group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold ${
                        isActive
                          ? "text-indigo-600 bg-gray-50"
                          : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                      }`
                    }
                  >
                    <Cog6ToothIcon
                      aria-hidden="true"
                      className="size-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                    />
                    Site Settings
                  </NavLink>
                </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="items-center border-b border-gray-200 bg-white">
              <NavLink className="flex h-16 shrink-0 items-center" to="/site">
                <img
                  src={LogonLogo}
                  alt="Did Not Load"
                  className="h-8 w-auto"
                />

                <div className="logo-name__name">
                  <b>{currentUserSiteName}</b>
                </div>

                <div className="px-6">
                  <TbArrowsDownUp />
                </div>
              </NavLink>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          end
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? "bg-gray-50 text-indigo-600"
                                : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                              "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold"
                            )
                          }
                        >
                          {/* Use a child function so we can style the icon based on isActive */}
                          {({ isActive }) => (
                            <>
                              <item.icon
                                className={classNames(
                                  isActive
                                    ? "text-indigo-600"
                                    : "text-gray-400 group-hover:text-indigo-600",
                                  "size-6 shrink-0"
                                )}
                              />
                              {item.name}
                            </>
                          )}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
                {/*} <li>
                  <div className="text-xs/6 font-semibold text-gray-400">
                    Your teams
                  </div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? "bg-gray-50 text-indigo-600"
                              : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                            "group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold"
                          )}
                        >
                          <span
                            className={classNames(
                              team.current
                                ? "border-indigo-600 text-indigo-600"
                                : "border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600",
                              "flex size-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium"
                            )}
                          >
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>*/}
                <li className="mt-auto">
                  <NavLink
                    to="/sitesettings"
                    className={({ isActive }) =>
                      `group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold ${
                        isActive
                          ? "text-indigo-600 bg-gray-50"
                          : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                      }`
                    }
                  >
                    <Cog6ToothIcon
                      aria-hidden="true"
                      className="size-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                    />
                    Site Settings
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-1 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              onClick={() => setSidebarOpen(true)}
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="size-6" />
            </button>

            {/* Separator */}
            <div
              aria-hidden="true"
              className="h-6 w-px bg-gray-200 lg:hidden"
            />

            {/* Dynamic page title */}
            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="flex-1 px-4 py-5 sm:px-6">
                <h3 className="text-base font-semibold text-gray-900">
                  {pageTitle}
                </h3>
              </div>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/* Separator */}
                <div
                  aria-hidden="true"
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <MenuButton className="-m-1.5  flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <div className="flex items-center justify-center h-10 w-10 rounded-full bg-gray-100 text-gray-900 font-bold">
                      <b>
                        {currentFirstName?.charAt(0)}
                        {currentLastName?.charAt(0)}
                      </b>
                    </div>
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        aria-hidden="true"
                        className="ml-4 text-sm/6 font-semibold text-gray-900"
                      >
                        {currentFirstName} {currentLastName}
                      </span>
                      <ChevronDownIcon
                        aria-hidden="true" 
                        className="ml-2 size-5 text-gray-400"
                      />
                    </span>
                  </MenuButton>
                  <MenuItems
                    transition
                    className="absolute right-0 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    {userNavigation.map((item) => (
                      <MenuItem key={item.name}>
                        {item.onClick ? (
                          <button
                            onClick={item.onClick}
                            className="block w-full px-3 py-1 text-left text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                          >
                            {item.name}
                          </button>
                        ) : (
                          <NavLink
                            to={item.href}
                            end
                            className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                          >
                            {item.name}
                          </NavLink>
                        )}
                      </MenuItem>
                    ))}
                  </MenuItems>
                </Menu>
              </div>
            </div>
          </div>

          <main className="">
            <div className="px-4 sm:px-6 lg:px-5">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
